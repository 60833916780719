import React, { useState } from 'react';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import HeroSection from 'components/HeroSection';
import SideBySide from 'features/sideBySide';
import DetailsInfo from 'components/DetailsInfo';
import FeaturedVehicles from './FeaturedVehicles';
import { generateContentfulAssetURL } from 'utils/image';
import UpcomingAuctions from 'features/UpcomingAuctions/UpcomingAuctions';
import styles from './FeaturedVehicles.module.scss';
import FirstToKnow from 'components/FirstToKnowForm';
import AuctionFirstToKnow from 'components/AuctionFirstToKnow/AuctionFirstToKnow';
import { useMediaQuery } from 'react-responsive';
import FirstToKnowModal from 'features/vehicleDetails/beFirstToKnowModal/FIrstToKnowModal';
import { useAuthenticatedMutation } from 'services/graphql/hooks';
import {
  SEND_EMAIL,
  SEND_TERMS_AND_CONDITION_EMAIL,
} from 'services/graphql/queries/vehicles-catalogue';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
// import { amplitudeEvents, trackEvent } from 'utils/amplitude';
import MailSubmittedModal from 'components/mailSubmittedModal';
import { isBrowser } from 'shared/helpers/vehicles';

const AuctionDetailsOnline = ({ auction, featuredVehicles, user, slug }) => {
  const auctionData = auction?.auction;
  const webPageAuctionData = auction.webpage__auction?.[0];
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const seoImage = {
    url: generateContentfulAssetURL(auction.featureImage?.file?.url),
    width: auction.featureImage?.file?.details?.image?.width,
    height: auction.featureImage?.file?.details?.image?.height,
  };
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  const [isForm, setIsForm] = useState(true);
  const [
    sendEmail,
    { error: sendEmailError, data: emailData, loading: isSendingEmail },
  ] = useMutation(SEND_TERMS_AND_CONDITION_EMAIL);
  const toggleIsForm = () => {
    setIsForm(!isForm);
  };
  const submit = async values => {
    if (window.dataLayer)
      window.dataLayer.push({
        event: 'contact-us-submit-form',
      });
    const fields = {
      ...values,
      title: `Be the First to Know Request - ${auction?.name}`,
    };
    delete fields.confirmEmail;
    await sendEmail({
      variables: {
        fields,
      },
    }).then(res => {
      setIsForm(false);
      if (isMobile) {
        setTimeout(() => {
          toggleModal();
        }, 5000);
      }
    });
  };
  // trackEvent(amplitudeEvents.VIEW_AUCTION_PAGE, {
  //   email_address: user?.email,
  //   auction_name: auction?.name,
  // });
  return (
    <Layout
      withOutBodyPadding={true}
      transparentHeader={true}
      paddingTop={false}
    >
      {isSendingEmail && <Spinner />}
      {sendEmailError && <Alert color="danger" msg={sendEmailError.message} />}
      <FirstToKnowModal
        isAuctionDetails={true}
        isOpen={openModal}
        toggle={toggleModal}
        isForm={isForm}
        submit={submit}
        auction={auction?.title}
      />
      <SEO
        title={auction.name}
        image={seoImage}
        link={`${isBrowser() && window.location.origin}/auction/${slug}`}
        metaTitle={
          auctionData?.metaTitle ?? `${auctionData.name}| Gooding & Company.`
        }
        description={
          auctionData?.metaDescription?.childMarkdownRemark?.rawMarkdownBody ??
          `Join the market leading global auction house for our ${auctionData?.name}.View the online catalogue and register to bid to stay up to date with the latest news.`
        }
      />
      <HeroSection
        key={`auctionsHeroSection`}
        image={auction.featureImage}
        title={auction.name}
        titleWithoutMargin={auction.subTitle ? true : false}
        description={auction.subTitle?.childMarkdownRemark?.html}
        callToActions={webPageAuctionData?.ctaList}
        isAuctionDetails={true}
        isMobile={isMobile}
        toggleModal={toggleModal}
      />
      {!isMobile && (
        <div className={styles.firstToKnow}>
          <AuctionFirstToKnow
            submit={submit}
            auction={auction?.auction?.name}
          />
          {!isForm && <MailSubmittedModal />}
        </div>
      )}

      {webPageAuctionData?.sideBySideSection && (
        <>
          <SideBySide
            sections={[
              {
                paragraph: webPageAuctionData.sideBySideSection.leftParagraph,
                headline: webPageAuctionData.sideBySideSection.title,
                callToAction:
                  webPageAuctionData.sideBySideSection?.ListCallToAction,
              },
              {
                paragraph: webPageAuctionData.sideBySideSection.rightParagraph,
              },
            ]}
            backgroundColor={'#fff'}
          />
        </>
      )}

      {webPageAuctionData?.detailsList?.length > 0 && (
        <DetailsInfo
          info={webPageAuctionData.detailsList}
          cutAfter={250}
          maxHeight={115}
        />
      )}

      {featuredVehicles?.length > 0 && (
        <FeaturedVehicles vehicles={featuredVehicles} auction={auction} />
      )}
      <div className={styles.upcomingSection}>
        <UpcomingAuctions
          details={true}
          fullWidth={true}
          auctionData={auction}
        />
      </div>
    </Layout>
  );
};

export default AuctionDetailsOnline;
